<template>
	<!--TODO: Сделать поддержку Английского языка-->
	<div class="aboutPoint">
		<v-row style="max-width: 1600px" class="mx-auto" justify="center">
			<v-col cols="12">
				<br>
				<v-card class="mx-auto elevation-6" :loading="loading">
					<v-toolbar color="blue darken-3 elevation-3" dark>
						<v-toolbar-title>
							Точки
						</v-toolbar-title>
					</v-toolbar>
					<v-data-table
						dense
						:loading="loading"
						no-data-text="Нет данных"
						:items="points"
						:headers="headers"
						:loading-text="$t('common.loading')"
						:footer-props="{
                            pageText: '{0} - {1} из {2}',
                            itemsPerPageText: 'Показывать на странице',
                            itemsPerPageAllText: 'все',
                            showFirstLastPage: true,
                            itemsPerPageOptions: [5, 10, 15, 30, -1],
                        }"
					>
						<template v-slot:item="{item}">
							<tr>
								<td >
									<v-row dense>
										<v-col>
											<b class="ml-1">{{ item.name }}</b>
										</v-col>
									</v-row>
								</td>
								<td>
									<v-row class="flex-nowrap rowAlignItemsCenter" v-if="!item.editAddressFlag">
										<b class="ml-1">{{item.address }}</b>
										<v-btn icon @click="editButtonHandler(item.id, 'address')">
											<v-icon small>mdi-pencil</v-icon>
										</v-btn>
									</v-row>
									<v-row v-if="item.editAddressFlag">
										<v-text-field
											dense
											v-model="item.tmpAddress"
											prepend-icon="mdi-close-circle"
											append-icon="mdi-content-save"
											@click:prepend="editButtonHandler(item.id, 'address')"
											@click:append="save(item.id, 'address')"
										/>
									</v-row>
								</td>
								<td>
									<v-row class="flex-nowrap rowAlignItemsCenter" v-if="!item.editCityFlag">
										<b class="ml-1">{{item.city }}</b>
										<v-btn icon @click="editButtonHandler(item.id, 'city')">
											<v-icon small>mdi-pencil</v-icon>
										</v-btn>
									</v-row>
									<v-row v-if="item.editCityFlag">
										<v-text-field
											dense
											v-model="item.tmpCity"
											prepend-icon="mdi-close-circle"
											append-icon="mdi-content-save"
											@click:prepend="editButtonHandler(item.id, 'city')"
											@click:append="save(item.id, 'city')"
										/>
									</v-row>
								</td>
								<td>
									<v-row class="flex-nowrap rowAlignItemsCenter" v-if="!item.editCountryFlag">
										<b class="ml-1">{{item.country }}</b>
										<v-btn icon @click="editButtonHandler(item.id, 'country')">
											<v-icon small>mdi-pencil</v-icon>
										</v-btn>
									</v-row>
									<v-row v-if="item.editCountryFlag">
										<v-text-field
											dense
											v-model="item.tmpCountry"
											prepend-icon="mdi-close-circle"
											append-icon="mdi-content-save"
											@click:prepend="editButtonHandler(item.id, 'country')"
											@click:append="save(item.id, 'country')"
										/>
									</v-row>
								</td>
								<td>
									<v-row class="flex-nowrap rowAlignItemsCenter" v-if="!item.editTimezoneFlag">
										<b class="ml-1">{{item.timezone }}</b>
										<v-btn icon @click="editButtonHandler(item.id, 'timezone')">
											<v-icon small>mdi-pencil</v-icon>
										</v-btn>
									</v-row>
									<v-row v-if="item.editTimezoneFlag">
										<v-text-field
											dense
											v-model="item.tmpTimezone"
											prepend-icon="mdi-close-circle"
											append-icon="mdi-content-save"
											@click:prepend="editButtonHandler(item.id, 'timezone')"
											@click:append="save(item.id, 'timezone')"
										/>
									</v-row>
								</td>
								<td>
									<v-row justify="center" dense>
										<v-col cols="5">
											<v-select
												:items="fieldOfActivities"
												v-model="item.field_of_activity.value"
												@change="changeFieldOfActivities(item.id, item.field_of_activity)"
											/>
										</v-col>
										<v-spacer />
										<v-col cols="6" v-show="item.field_of_activity.value == fieldOfActivities[0].value">
											<v-select
												:items="typesOfKitchen"
												v-model="item.field_of_activity.kitchen"
												label="Кухня"
												multiple
												@change="changeFieldOfActivities(item.id, item.field_of_activity)"
											>
												<template v-slot:selection="{index}">
													<v-chip small v-if="index < 1">
														<span>
															{{typesOfKitchen[item.field_of_activity.kitchen[index]].text}}
														</span>
													</v-chip>
													<span v-if="index==1">
														(+{{item.field_of_activity.kitchen.length - 1}} и другие)
													</span>
												</template>
											</v-select>
										</v-col>
									</v-row>
								</td>
								<td>
									<v-row justify="space-around" class="avgCheck" >
										<v-col>{{ item.average_check }} ₽</v-col>
										<v-col>
											<v-btn
												small
												@click="generateAverageCheck(item.id)"
											>
												Сгенерировать
											</v-btn>
										</v-col>
									</v-row>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import config from '../../config'
    import Notify from '../../mixins/Notify.js'

	export default {
		name: "aboutPoint",
        mixins: [Notify],
		data() {
			return {
				interfaceCardMd: 3,
				interfaceCardSm: 6,
				userIsPoint: false,
				loading: false,
				points: [],
				headers: [
					{
						text: "Название",
						value: 'name',
					},
					{
						text: "Адрес",
						value: 'address',
					},
					{
						text: "Город",
						value: 'city',
					},
					{
						text: "Страна",
						value: 'country',
					},
					{
						text: "Часовой пояс",
						value: 'timezone',
					},
					{
						text: "Сфера деятельности",
						value: 'field_of_activity',
						sortable: false
					},
					{
						text: "Средний чек",
						value: 'status',
						sortable: true
					},
				],
				fieldOfActivities: [
					{
						text: 'Общепит',
						value: 0
					},
					{
						text: 'Парфюм',
						value: 1
					},
					{
						text: 'Одежда',
						value: 2
					},
					{
						text: 'Цветы',
						value: 3
					},
				],
				typesOfKitchen: [
					{
						text: 'Корейская',
						value: 0,
					},
					{
						text: 'Грузинская',
						value: 1,
					},
					{
						text: 'Японская',
						value: 2,
					},
					{
						text: 'Итальянская',
						value: 3,
					},
					{
						text: 'Китайская',
						value: 4,
					},
					{
						text: 'Русская',
						value: 5,
					},
					{
						text: 'Другое',
						value: 6,
					},
				]
			}
		},
		mounted() {
			console.log('start')
			this.updateData()

		},
		methods: {
			//загрузка Точек
			updateData() {
				this.loading = true
				this.$store
					.dispatch('points_get')
					.then(res => {
						if (res.data.success) {
							let points = res.data.points.sort(this.sortPoints)
							points = points.map(p => {
								if (!p.average_check){
									p.average_check = 0
								}
								p.editAddressFlag = false
								p.editCityFlag = false
								p.editCountryFlag = false
								p.editTimezoneFlag = false
								return p
							})
							// console.log('points', points)
							// setTimeout(() => this.points = points, 2000)
							this.points = points

							this.loading = false
						}
					})
					.catch(err => {
						this.loading = false
						console.log(err)
					})
			},
			//Генерация среднего чека
			generateAverageCheck(pointId){
				console.log(`Генерация ср. чека для ${pointId}`)
				this.$store.state.loading = true
				this.$store.dispatch('point_average_check', {id: pointId})
					.then(res => {
						if (res.data.success) {
							this.points.find(el => el.id === pointId).average_check = res.data.changedPoint.average_check
						}
						this.$store.state.loading = false
					})
					.catch(err => {
						console.log(err)
						this.$store.state.loading = false
					})

			},
			changeFieldOfActivities(pointId, field){
				this.$store.state.loading = true
				if (!field.value == 0) {
					delete field.kitchen
				} else if (!field.kitchen){
					field.kitchen = []
				}
				this.$store.dispatch('point_fieldOfActivity_update', {id: pointId, field})
					.then(res => {
						if (res.data.success) {
							this.notifyToast("Данные успешно изменены", {color: "success", timeout: 2000})
						} else {
							this.notifyToast("Ошибка сохранения данных. Пожалуйста попробуйте еще раз.", {color: 'error', timeout: 4000})
							console.log(`Error: ${res.data.msg}`)
						}
					})
					.catch(err => {
						this.notifyToast("Ошибка сохранения данных. Пожалуйста попробуйте еще раз.", {color: 'error', timeout: 4000})
						console.log(`Error: ${err}`)
					})
					.finally(() => {
						this.$store.state.loading = false
					})
			},
			sortPoints(a, b) {
				if (a.name > b.name) {
					return 1
				}
				if (a.name < b.name) {
					return -1
				}
				if (a.address > b.address) {
					return 1
				}
				if (a.address < b.address) {
					return -1
				}
				return 0
			},
			editButtonHandler(pointId, type) {
				const point = this.points.find(point => point.id === pointId)
				switch (type) {
					case "address":
						if (point.editAddressFlag) {
							delete point.tmpAddress
							point.editAddressFlag = false
						} else {
							point.tmpAddress = point.address
							point.editAddressFlag = true
						}
						break
					case "city":
						if (point.editCityFlag) {
							delete point.tmpCity
							point.editCityFlag = false
						} else {
							point.tmpCity = point.city
							point.editCityFlag = true
						}
						break
					case "country":
						if (point.editCountryFlag) {
							delete point.tmpCountry
							point.editCountryFlag = false
						} else {
							point.tmpCountry = point.country
							point.editCountryFlag = true
						}
						break
					case "timezone":
						if (point.editTimezoneFlag) {
							delete point.tmpTimezone
							point.editTimezoneFlag = false
						} else {
							point.tmpTimezone = point.timezone
							point.editTimezoneFlag = true
						}
						break
				}

			},
			save(pointId, type) {
				this.loading = true
				const point = this.points.find(point => point.id === pointId)
				let newPoint = {}
				Object.assign(newPoint, point)
				if (type === 'address') newPoint.address = newPoint.tmpAddress.trim()
				if (type === 'city') newPoint.city = newPoint.tmpCity.trim()
				if (type === 'country') newPoint.country = newPoint.tmpCountry.trim()
				if (type === 'timezone') newPoint.timezone = newPoint.tmpTimezone.trim()
				delete newPoint.editAddressFlag
				delete newPoint.editCityFlag
				delete newPoint.editCountryFlag
				delete newPoint.editTimezoneFlag
				delete newPoint.tmpAddress
				delete newPoint.tmpCity
				delete newPoint.tmpCountry
				delete newPoint.tmpTimezone
				this.$store.dispatch('point_update', newPoint)
					.then(res => {
						if (res.data.success){
							point.address = res.data.point.address
							point.city = res.data.point.city
							point.country = res.data.point.country
							point.timezone = res.data.point.timezone
							if (type === 'address') point.editAddressFlag = false
							if (type === 'city') point.editCityFlag = false
							if (type === 'country') point.editCountryFlag = false
							if (type === 'timezone') point.editTimezoneFlag = false
							this.notifyToast("Данные успешно изменены", {color: "success", timeout: 2000})
						} else {
							this.notifyToast("Ошибка при сохранении данных", {color: "error", timeout: 2000})
						}
					})
					.catch(err => {
						console.log(err)
					})
					.finally(() => this.loading = false)
			}
		},
		computed: {
		}
	}
</script>

<style lang="stylus" scoped>
	.aboutPoint
		height 100%
		.fieldOfActivity
			display flex
			justify-content space-between
	.rowAlignItemsCenter
		align-items center
	.avgCheck
		flex-direction column
		.col
			padding 2px
</style>
